body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 要素を中央揃えにするコンテナ */
.centered-container {
  display: flex;
  justify-content: center; /* 横方向の中央揃え */
  align-items: center; /* 縦方向の中央揃え */
  height: 100vh; /* ビューポートの高さを全て使う */
}

/* ポケモンのタイプアイコンのスタイル */
.t_pokemonTypeContainer {
  display: flex; /* 横一列に並べる */
  justify-content: center; /* 中央揃え */
  align-items: center; /* アイテムを垂直方向に中央揃え */
  gap: 8px; /* アイコン間のスペース */
}

/* アイコンのサイズと角の丸みを調整 */
.icon {
  width: 32px; /* サイズを小さく */
  height: 32px; /* サイズを小さく */
  border-radius: 10px; /* 角を少し丸める */
  /* その他のスタイルは icons.css にて定義 */
}

.next_buton {
  flex-basis: 100%;
  margin: 20px 0;
  /* その他のスタイルを必要に応じて追加 */
}

/* 種族値リストのスタイル */
.base-stat-list {
  list-style-type: none; /* リストマーカーを非表示に */
  padding-left: 0; /* デフォルトのパディングを削除 */
}

.base-stats-container {
  display: flex;
  gap: 10px;
  justify-content: center; 
}

.base-stat-list {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  text-align: left;
}

.util_container {
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_container {
  width: 150px;
}

.poke_info_container {
  text-align: center;
}