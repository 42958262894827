.icon-cmn_type_bug_icon,.icon-cmn_type_dark_icon {
background-image: url(icons.png);
background-position: -532px -59px;
width: 52px;
height: 52px
}

.icon-cmn_type_dark_icon {
background-position: -532px -115px
}

.icon-cmn_type_dragon_icon,.icon-cmn_type_electric_icon {
background-image: url(icons.png);
background-position: -532px -171px;
width: 52px;
height: 52px
}

.icon-cmn_type_electric_icon {
background-position: -532px -227px
}

.icon-cmn_type_fairy_icon,.icon-cmn_type_fighting_icon,.icon-cmn_type_fire_icon {
background-image: url(icons.png);
background-position: -532px -283px;
width: 52px;
height: 52px
}

.icon-cmn_type_fighting_icon,.icon-cmn_type_fire_icon {
background-position: -532px -339px
}

.icon-cmn_type_fire_icon {
background-position: -532px -395px
}

.icon-cmn_type_flying_icon,.icon-cmn_type_ghost_icon,.icon-cmn_type_grass_icon {
background-image: url(icons.png);
background-position: -532px -451px;
width: 52px;
height: 52px
}

.icon-cmn_type_ghost_icon,.icon-cmn_type_grass_icon {
background-position: -590px 0
}

.icon-cmn_type_grass_icon {
background-position: -590px -56px
}

.icon-cmn_type_ground_icon,.icon-cmn_type_ice_icon,.icon-cmn_type_normal_icon {
background-image: url(icons.png);
background-position: -590px -112px;
width: 52px;
height: 52px
}

.icon-cmn_type_ice_icon,.icon-cmn_type_normal_icon {
background-position: -590px -168px
}

.icon-cmn_type_normal_icon {
background-position: -590px -224px
}

.icon-cmn_type_poison_icon,.icon-cmn_type_psychic_icon {
background-image: url(icons.png);
background-position: -590px -280px;
width: 52px;
height: 52px
}

.icon-cmn_type_psychic_icon {
background-position: -590px -336px
}

.icon-cmn_type_rock_icon,.icon-cmn_type_steel_icon,.icon-cmn_type_water_icon {
background-image: url(icons.png);
background-position: -590px -392px;
width: 52px;
height: 52px
}

.icon-cmn_type_steel_icon,.icon-cmn_type_water_icon {
background-position: -590px -448px
}

.icon-cmn_type_water_icon {
background-position: -590px -504px
}


.typeColor0 {
background-color: #9099a1
}

.typeColor1 {
background-color: #ce406a
}

.typeColor2 {
background-color: #8fa8dd
}

.typeColor3 {
background-color: #ab6ac8
}

.typeColor4 {
background-color: #d97745
}

.typeColor5 {
background-color: #c7b78b
}

.typeColor6 {
background-color: #90c12d
}

.typeColor7 {
background-color: #5369ac
}

.typeColor8 {
background-color: #598ea1
}

.typeColor9 {
background-color: #ff9c54
}

.typeColor10 {
background-color: #4e90d6
}

.typeColor11 {
background-color: #63bb5b
}

.typeColor12 {
background-color: #f4d23c
}

.typeColor13 {
background-color: #f97177
}

.typeColor14 {
background-color: #73cec0
}

.typeColor15 {
background-color: #0a6dc4
}

.typeColor16 {
background-color: #5a5366
}

.typeColor17 {
background-color: #ed8fe6
}

.t_typeColor0 {
background-color: #999999
}

.t_typeColor1 {
background-color: #ffa202
}

.t_typeColor2 {
background-color: #95c9ff
}

.t_typeColor3 {
background-color: #994dcf
}

.t_typeColor4 {
background-color: #ab7939
}

.t_typeColor5 {
background-color: #bcb889
}

.t_typeColor6 {
background-color: #9fa424
}

.t_typeColor7 {
background-color: #6e4570
}

.t_typeColor8 {
background-color: #6aaed3
}

.t_typeColor9 {
background-color: #ff612c
}

.t_typeColor10 {
background-color: #2992ff
}

.t_typeColor11 {
background-color: #42bf24
}

.t_typeColor12 {
background-color: #ffdb00
}

.t_typeColor13 {
background-color: #ff637f
}

.t_typeColor14 {
background-color: #42d8ff
}

.t_typeColor15 {
background-color: #5462d6
}

.t_typeColor16 {
background-color: #4f4747
}

.t_typeColor17 {
background-color: #ffb1ff
}
